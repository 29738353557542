*,
*::after,
*::before {
  box-sizing: border-box;
}

:root {
  font-size: 15px;
}

body {
  margin: 0;
  --color-text: #fff;
  --color-bg: #fff;
  --color-link: #ffffff;
  --color-link-hover: #6b6b6b;
  color: var(--color-text);
  background-color: var(--color-bg);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #333;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
  content: '';
  position: fixed;
  z-index: 1000;
}

.js .loading::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-bg);
}

.js .loading::after {
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  border-radius: 50%;
  opacity: 0.4;
  background: var(--color-link);
  animation: loaderAnim 0.7s linear infinite alternate forwards;
}

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(0.5, 0.5, 1);
  }
}

a {
  text-decoration: none;
  color: var(--color-link);
  outline: none;
}

a:hover,
a:focus {
  color: var(--color-link-hover);
  outline: none;
}

.frame {
  padding: 3rem 5vw;
  text-align: center;
  position: relative;
  z-index: 1000;
}

.frame__title {
  font-size: 1rem;
  margin: 0 0 1rem;
  font-weight: normal;
}

.frame__links {
  display: inline;
}

.frame__links a:not(:last-child),
.frame__demos a:not(:last-child) {
  margin-right: 1rem;
}

.frame__demos {
  margin: 1rem 0;
}

.frame__demo--current,
.frame__demo--current:hover {
  color: var(--color-text);
}

.content {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: calc(100vh - 13rem);
  position: relative;
  justify-content: flex-start;
  align-items: center;
}

/*@media screen and (min-width: 53em) {*/
  .frame {
    position: fixed;
    text-align: left;
    z-index: 100;
    top: 0;
    left: 0;
    display: grid;
    align-content: space-between;
    width: 100%;
    max-width: none;
    height: 100vh;
    padding: 3rem;
    pointer-events: none;
    grid-template-columns: 75% 25%;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'title links'
      '... ...'
      '... demos';
  }
  .frame__title-wrap {
    grid-area: title;
    display: flex;
  }
  .frame__title {
    margin: 0;
  }
  .frame__tagline {
    position: relative;
    margin: 0 0 0 1rem;
    padding: 0 0 0 1rem;
    opacity: 0.5;
  }
  .frame__demos {
    margin: 0;
    grid-area: demos;
    justify-self: end;
  }
  .frame__links {
    grid-area: links;
    padding: 0;
    justify-self: end;
  }
  .frame a {
    pointer-events: auto;
  }
  .content {
    height: 100vh;
    justify-content: center;
  }
/*}*/
